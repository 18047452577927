<template>
    <div class="dm_feed_page h-100">
        <!-- Navigation -->
        <header-vue></header-vue>

        <!-- Page Content -->
        <div class="container pb-5 pb-md-0">

            <div class="row">

                <!-- Feeds -->
                <div class="col-12 col-lg-8">

                    <!-- Search -->
                    <div class="input-group my-4 dm_feed_search">
                        <!-- <i class="fas fa-search dm_absolute dm_v_center"></i> -->
                       <!--  <input @change="filterPost()" v-model="search_text" type="text" class="form-control mr-2 dm_card_shadow dm_search_field h-100" aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder="Search the feed..."> -->
                        <div class="input-group-append" style="width: 100%;">
                            <select v-model="gender" class="custom-select mr-2 py-2 px-3 dm_card_shadow h-100"
                                id="inputGroupSelect01">
                                <option value="" selected>Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                            <select v-model="breed_id" class="custom-select mr-2 py-2 px-3 dm_card_shadow h-100"
                                id="inputGroupSelect01">
                                <option value="" selected>Breed</option>
                                <option v-for="b in breeds" :key="b.id" :value="b.id">{{ b.name }}</option>
                            </select>
                            <select class="custom-select py-2 px-3 dm_card_shadow h-100" v-model="state"
                                id="inputGroupSelect01">
                                <option value="" selected>State</option>
                                <option v-for="b in states" :key="b.id" :value="b.id">{{ b.name }}</option>
                            </select>
                            <button style="width: 50% !important;margin-left: 10px;" @click="filterPost()"
                                class="dm_btn_dark_solid py-2 px-3 h-100"><i class="fas fa-search"
                                    style="padding-left: 0px;;padding-right: 10px;z-index: 4;"></i>Search</button>
                        </div>
                    </div>

                    <!-- New Post -->
                    <div class="card mb-4 dm_new_post dm_card_shadow">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group d-flex align-items-start">
                                        <div class="">
                                            <div class="dm_feed_avatar">
                                                <img v-if="user.name" :src="API_URL+user.name">
                                                <img v-else src="https://via.placeholder.com/150">
                                            </div>
                                        </div>
                                        <div class="pl-1" style="width: 100%">
                                            <textarea class="dm_post_area form-control border-0 shadow-none input-description" rows="5"
                                                placeholder="Post something..."></textarea>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="card-footer p-0 d-flex justify-content-between">
                                        <input onchange="window.readFile(this);" type="file" name="image"
                                            class="input-file" style="display: none;" id="upload-file" accept="image/*"
                                            multiple>
                                        <button @click="triggerFileInput()" class="dm_btn_med_outline py-2 px-md-5 px-3"><i
                                                class="fas fa-camera mr-2"></i>Photo</button>
                                        <button @click="createPostData()" class="dm_btn_dark_solid py-2 px-md-5 px-3">Post
                                            Status</button>
                                    </div>
                                    <!-- <hr> -->
                                    <div class="card-footer p-0 d-flex justify-content-between">
                                        <div id="post_image_status"></div>
                                        <div id="post_photos" class="row"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Blog Post -->
                    <div class="card mb-4 dm_feed_posts" v-for="(post, index) in posts" v-bind:key="post.id">
                        <div class="card-body p-3" v-if="posts.length > 0">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card-header border-0 p-0" v-if="post.user">
                                        <div class="d-flex">
                                            <div class="">
                                                <div class="dm_feed_avatar" v-if="typeof post.user.photo != 'undefined'">
                                                    <img v-if="!post.user.photo || post.user.photo_id === null || post.user.photo.name == null"
                                                        src="https://via.placeholder.com/50">
                                                    <img v-else
                                                        :src="post.user.photo && post.user.photo.name != null ? API_URL+post.user.photo.name : 'https://via.placeholder.com/50'">
                                                </div>
                                            </div>
                                            <div class="pl-3">
                                                <div class="dm_feed_username" style="cursor: pointer;"
                                                    @click="openProfile(post.user.id)">
                                                    <h5 class="m-0">{{ post.user.dog_name }}</h5>
                                                    <div class="dm_dog_breed" v-if="post.user.dog_name"><i
                                                            class="fas fa-user"></i> {{ post.user.username }}</div>
                                                    <span>{{ frontEndDateFormat(post.created_at) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row py-2">
                                        <div class="col-12">
                                            <div class="dm_post_content">
                                                <p class="m-0 py-4">
                                                    {{ post.desc }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-6" v-for="image in post.images" v-bind:key="image.id">
                                            <div class="dm_post_content" style="margin-top: 20px">
                                                <img :src="image.image_path" width="100%">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row py-2">
                                        <div class="col-12">
                                            <div
                                                class="dm_post_info_wrapper d-block d-md-flex justify-content-between align-items-center">
                                                <div class="dm_post_likedby d-flex mb-2 mb-md-0">
                                                    <div class="dm_likes_avatar mr-2">
                                                        <span v-for="(likes, i) in post.likes" v-bind:key="likes.id"
                                                            :likes="likes[i]">
                                                            <span v-if="post.likes[i].user && typeof post.likes[i].user.photo != 'undefined'">
                                                                <span
                                                                    v-if="!post.likes[i].user.photo || post.likes[i].user.photo_id === null || post.likes[i].user.photo.name == null">
                                                                    <img src="https://via.placeholder.com/50" width="15">
                                                                </span>
                                                                <img v-else
                                                                    :src="post.likes[i].user.photo && post.likes[i].user.photo.name != null ? API_URL+post.likes[i].user.photo.name : 'https://via.placeholder.com/50'"
                                                                    width="15">
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <span v-if="post.likes[0] && post.likes[0].user">
                                                        <p v-if="post.total_likes == 0" class="m-0">{{post.total_likes}}
                                                            liked this</p>
                                                        <p @click="viewLikesModal(post.id)" v-if="post.total_likes == 1"
                                                            class="m-0">{{post.likes[0].user.dog_name}} liked this</p>
                                                        <p @click="viewLikesModal(post.id)" v-if="post.total_likes == 2"
                                                            class="m-0">{{post.likes[0].user.dog_name}} &
                                                            {{post.likes[1].user.dog_name}} liked this</p>
                                                        <p @click="viewLikesModal(post.id)" v-else-if="post.total_likes > 2"
                                                            class="m-0">{{post.likes[0].user.dog_name}} ,
                                                            {{post.likes[1].user.dog_name}} & {{post.total_likes}} other
                                                            liked this</p>
                                                    </span>
                                                </div>
                                                <div class="dm_post_info d-flex">
                                                    <div v-if="post.is_liked == 0" @click="likePost(post.id)"
                                                        class="dm_paw mr-4 d-flex"><i class="fas fa-paw mr-2"></i>
                                                        <p class="m-0">Give a paw</p>
                                                    </div>
                                                    <div v-else @click="disLikePost(post.id)"
                                                        class="dm_paw mr-4 d-flex"><i style="color: #c8c7c7"
                                                            class="fas fa-paw mr-2"></i>
                                                        <p class="m-0">Erase a paw</p>
                                                    </div>
                                                    <div @click="viewCommentsModal(post.id)"
                                                        class="dm_bark mr-4 d-flex"><img
                                                            src="../../assets/img/bark@2x.png" height="16" class="mr-2">
                                                        <p class="m-0">{{post.total_comments}} Barks</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="dm_post_comments" v-if="post.total_comments > 0">
                                        <div class="col-sm-12 col-12 p-0 pb-4"
                                            v-for="comment in post.comments.slice().reverse()" v-bind:key="comment.id">
                                            <div class="dm_feed_username p-3">
                                                <h6 class="m-0 pb-1">{{ comment.user.dog_name }}</h6>
                                                <div class="dm_dog_breed pb-3"><i class="fas fa-user"></i>
                                                    {{ comment.user.username }}</div>
                                                <p class="mb-0 pb-1">{{ comment.desc }}</p>
                                                <p class="dm_feed_date mb-0">
                                                    {{ frontEndDateFormatTime(comment.created_at) }}</p>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="card-footer border-0 p-0 d-flex justify-content-between">
                                        <div class="dm_feed_avatar">
                                            <img v-if="user.name" :src="API_URL+user.name">
                                            <img v-else src="https://via.placeholder.com/50">
                                        </div>
                                        <textarea @keydown.enter.shift.exact="newline" @keydown.enter.exact.prevent
                                            @keyup.enter.exact="createComment(post.id)"
                                            class="form-control border-0 shadow-none" :class="'comment_post_'+post.id"
                                            rows="1" :key-index="index" placeholder="Bark on the post..."></textarea>
                                        <a @click="createComment(post.id)">
                                            <i style="font-size: 25px;padding: 10px;" class="fas fa-paper-plane"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-4 dm_feed_posts" v-if="posts.length == 0">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12">
                                    No Posts Found
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Pagination -->
                    <!-- <ul class="pagination justify-content-center mb-4">
                        <li class="page-item">
                            <a class="page-link" href="#">&larr; Older</a>
                        </li>
                        <li class="page-item disabled">
                            <a class="page-link" href="#">Newer &rarr;</a>
                        </li>
                    </ul> -->

                </div>

                <!-- Sidebar Widgets Column -->
                <div class="col-lg-4 d-none d-lg-block">
                    <div class="dm_sidebar">
                        <!-- Profile info Widget -->
                        <div class="card my-4 dm_card_shadow d-none d-md-flex">
                            <div class="card-header text-uppercase">Your Profile</div>
                            <div class="card-body p-3">
                                <div class="d-flex">
                                        <div class="dm_side_avatar p-0">
                                            <img v-if="user.name" :src="API_URL+user.name">
                                            <img v-else src="https://via.placeholder.com/150">
                                        </div>
                                        <div class="dm_side_username p-0 pl-3">
                                            <h5 class="m-0">{{ user.dog_name }}</h5>
                                            <div class="dm_dog_breed my-2"><i class="fas fa-user mr-2"></i> {{ user.username }}</div>
                                            <div class="dm_dog_breed my-2"><i class="fas fa-dog mr-2"></i> {{user.breed_name}}</div>
                                        <div v-if="user.dob" class="dm_dog_dob my-2"><i class="fas fa-birthday-cake mr-2"></i>{{user.dob}}</div>
                                        <div v-if="user.gender" class="dm_dog_gender my-2"><i class="fas fa-venus-mars mr-2"></i>{{user.gender}}</div>

                                        </div>
                                </div>
                            </div>
                        </div>

                        <!-- Search Widget -->
                        <div class="input-group my-4 dm_feed_search d-none d-md-flex">
                            <i class="fas fa-search dm_absolute dm_v_center"></i>
                            <input v-model="search_online_text" type="text" class="form-control mr-2 dm_card_shadow dm_search_field h-100"
                                aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                placeholder="Search your mates...">
                        </div>


                        <!-- Online friends Widget -->
                        <div class="card my-4 dm_card_shadow d-none d-md-flex">
                            <div class="card-header text-uppercasebold">Online now</div>
                            <div class="card-body p-3">
                                <div class="d-flex align-items-center" v-for="(list, index) in filterBy" v-bind:key="list.id" style="padding-bottom: 15px;" @click="openChatById(list.id)">
                                    <div class="p-0" :key="index">
                                        <div class="dm_side_avatar" :class="list.userList[0].is_online ? 'avatar-online' : 'avatar-offline'">
                                            <img v-if="list.userList[0].photo" :src="API_URL+list.userList[0].photo.name">
                                            <img v-else src="https://via.placeholder.com/150">
                                        </div>
                                    </div>
                                    <div class="p-0 pl-3 w-100">
                                        <div class="dm_side_username d-flex justify-content-between">
                                            <h5 class="m-0">{{ list.userList[0].dog_name }} <br> <div class="dm_dog_breed"><i class="fas fa-user"></i> {{ list.userList[0].username }}</div></h5>
                                            <span v-if="!list.userList[0].is_online" class="dm_online_status pr-3">{{ lastSeenDateFormatTime(list.userList[0].last_seen) }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center" v-if="user_list.length == 0">
                                    <div class="dm_side_username ml-2 d-flex justify-content-between">
                                        No Users found
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <!-- /.row -->

        </div>
        <!-- /.Modals -->
        <div class="modal likes-modal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Total Post Likes</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="card-header border-0 p-0 dm_post_comments">
                    <div class="d-flex mb-3" v-for="like in likes" v-bind:key="like.id">
                        <div class="">
                            <div class="dm_feed_avatar">
                                <img v-if="like.user.photo" :src="API_URL+like.user.photo.name">
                                <img v-else src="https://via.placeholder.com/50">
                            </div>
                        </div>
                        <div class="pl-3">
                            <div class="">
                                <h5 class="m-0">{{ like.user.dog_name }}</h5>
                                <div class="dm_dog_breed"><i class="fas fa-user"></i> {{ like.user.username }}</div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="modal-footer">
              </div>
            </div>
          </div>
        </div>

        <div class="modal comments-modal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Total Post Barks</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="card-header border-0 p-0 dm_post_comments">
                    <div class="d-flex mb-3 dm_feed_username p-3" v-for="(like,i) in comments" v-bind:key="like.id">
                        <div class="col-1 p-0">
                            <div class="dm_feed_avatar">
                                <span v-if="!comments[i].user.photo || comments[i].user.photo_id === null || comments[i].user.photo.name == null">
                                    <img src="https://via.placeholder.com/50" width="15">
                                </span>
                                <img v-else :src="comments[i].user.photo && comments[i].user.photo.name != null ?API_URL+comments[i].user.photo.name : 'https://via.placeholder.com/50'" width="15">
                            </div>
                        </div>
                        <div class="p-0 pl-4 col-11">
                            <div class="">
                                <h5 class="m-0 pb-1">{{ like.user.dog_name }}</h5>
                                <div class="dm_dog_breed pb-3"><i class="fas fa-user"></i> {{ like.user.username }}</div>
                                <p class="mb-0 pb-1">{{ like.desc }}</p>
                                <p class="dm_feed_date mb-0">{{ frontEndDateFormatTime(like.created_at) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="modal-footer">
                    <div class="card-footer border-0 p-0 d-flex justify-content-between" style="width: 100%;">
                        <div class="dm_feed_avatar">
                            <img v-if="user.name" :src="API_URL+user.name">
                            <img v-else src="https://via.placeholder.com/50">
                        </div>
                        <textarea  @keydown.enter.shift.exact="newline" :class="'modal_comment_post_'+post_id" @keydown.enter.exact.prevent @keyup.enter.exact="createComment(post_id)" class="form-control border-0 shadow-none"  rows="1" placeholder="Give a bark on the post..."></textarea> <a @click="createComment(post_id)"><i style="font-size: 25px;padding: 10px;" class="fas fa-paper-plane"></i></a>
                    </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.container -->
        <footer-vue></footer-vue>
    </div>
</template>
<script>
import Headervue from '../partials/header-vue'
import Footervue from '../partials/footer-vue'
import {API_PROFILE_IMAGE_URL} from '../../constants'

export default {
    name: "posts",
    data: () => ({
      user:[],
      user_list:[],
      posts:[],
      breeds:[],
      likes:[],
      comments:[],
      states:[],
      search_text:'',
      breed_id:'',
      state:'',
      gender:'',
      errors:{},
      image:'',
      title:'',
      desc:'',
      post_id:0,
      API_URL:API_PROFILE_IMAGE_URL,
      files:'',
      count:1,
      search_online_text:'',
      selected_comment:0,
    }),
    components: {
      'header-vue': Headervue,
      'footer-vue': Footervue
    },
    computed: {
      filterBy () {
        const search = this.search_online_text.toLowerCase().trim();

          if (!search)
            return this.user_list;

          return this.user_list.filter(c => c.userList[0].dog_name.toLowerCase().indexOf(search) > -1 || c.userList[0].username.toLowerCase().indexOf(search) > -1);
      }
    },
    sockets: {
        post: function (data) {
            this.posts.unshift(data);
        },
        'post-likes': function(data) {
            var _this = this;
            for (var i = _this.posts.length - 1; i >= 0; i--) {
                if(_this.posts[i].id == data.post_id){
                    _this.post_id = data.post_id;
                    _this.index = i;
                    _this.updatePostLikesById();
                }
            }
        },
        'post-comment': function(data) {
            var _this = this;
            for (var i = _this.posts.length - 1; i >= 0; i--) {
                if(_this.posts[i].id == data.comment.post_id){
                    _this.post_id = data.comment.post_id;
                    _this.index = i;
                    _this.updatePostCommentById();
                }
            }
            if((_this.comments[0] && _this.comments[0].post_id == data.comment.post_id) || (_this.selected_comment == data.comment.post_id)){
                _this.comments.push(data.comment);
            }
        }
    },
    methods: {
        likePost: function (post_id) {
            this.$socket.emit('user:like-post', {
                post_id: post_id
            }, function (data) {
                if(data){
                    window.notify(true, 'Post Liked successfully');
                }
            });
        },
        disLikePost: function (post_id) {
            this.$socket.emit('user:dislike-post', {
                post_id: post_id
            }, function (data) {
                if(data){
                    window.notify(true, 'Post DisLiked successfully');
                }
            });
        },
        filterPost: function() {
            if(this.gender == '' || this.breed_id == '' || this.state == ''){
                window.notify(false, 'Please select all three fields');
                return false;
            }
            this.getPostsList();
        },
        triggerFileInput: function() {
            window.$('.input-file').trigger('click');
        },
        createPostData: function () {
            if (this.$parent.checkSubcription()) {
                this.title = window.$(".input-description").val();
                this.desc = window.$(".input-description").val();
                this.files = window.$("input[type=file]")[0].files;
                // if (window.$(".input-description").val() == '') {
                //     window.notify(false, 'Please add description');
                //     return false;
                // }
                this.createPost();
            }
        },
        viewLikesModal: function(post_id) {
            this.post_id = post_id;
            this.getLikeList();
        },
        viewCommentsModal: function(post_id) {
            if(this.comments.length == 0){
                this.selected_comment = post_id;
            }
            this.post_id = post_id;
            this.getCommentList();
        },
        openProfile: function(user_id) {
            localStorage.setItem('user_id', user_id);
            this.$router.push({ path: 'profile' })
        },
        frontEndDateFormat: function(dates) {
            let date = window.moment(dates);
            return window.moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY');
        },
        frontEndDateFormatTime: function(dates) {
            let date = window.moment(dates);
            return window.moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY hh:mm A');
        },
        lastSeenDateFormatTime: function(dates) {
            var exp = window.moment();
            var now = window.moment(dates);

            let days = exp.diff(now, 'days');
            let hours = exp.diff(now, 'hours') - (days * 24);
            let minutes = exp.diff(now, 'minutes') - ((days * 1440) + (hours * 24) * 60);

            if(dates != null){
                if(days > 0){
                    return days+' Days ago'
                }else if(hours > 0){
                    return hours+' Hours ago';
                }else{
                    return minutes+' Minutes ago';
                }
            }else{
                return '';
            }
        },
        openChatById: function () {
            this.$router.push({
                path: '/'
            })
            this.$router.push({
                path: 'chat'
            })
        },
        async updatePostById() {
            var _this = this;
            let form = {
                post_id: this.post_id
            };
            let responce = await this.$store.dispatch("getPostsByID",form);
            _this.posts[_this.index].likes = responce.data.data.likes;
            _this.$forceUpdate();
            _this.post_id = 0;

        },
        async updatePostCommentById() {
            var _this = this;
            let form = {
                post_id: this.post_id
            };
            let responce = await this.$store.dispatch("getPostsByID",form);
            _this.posts[_this.index].total_comments = responce.data.data.total_comments;
            _this.posts[_this.index].comments = responce.data.data.comments;
            _this.$forceUpdate();
            _this.post_id = 0;

        },
        async updatePostLikesById() {
            var _this = this;
            let form = {
                post_id: this.post_id
            };
            let responce = await this.$store.dispatch("getPostsByID",form);
            _this.posts[_this.index].likes = responce.data.data.likes;
            _this.posts[_this.index].total_likes = responce.data.data.total_likes;
            _this.posts[_this.index].is_liked = responce.data.data.is_liked;
            _this.$forceUpdate();
            _this.post_id = 0;

        },
        async createPost() {
            let form = {
                image: this.files,
                title: this.title,
                desc: this.desc
            };
            let responce = await this.$store.dispatch("createPost", form);
            if(responce.data.status){
                window.notify(responce.data.status, 'Post added successfully');
                window.$('.emojionearea-editor').text('');
                window.$('.input-description').val('');
                window.$("input[type=file]").val('');
                window.$("#post_photos").html('');
            }
        },
        async createComment(post_id) {
            if (this.$parent.checkSubcription()) {
                let form = {
                    post_id: post_id,
                    desc: typeof window.$('.modal_comment_post_'+post_id).val() != 'undefined' ? window.$('.modal_comment_post_'+post_id).val() : window.$('.comment_post_'+post_id).val()
                };
                let responce = await this.$store.dispatch("createComment", form);
                if(responce.data.status){
                    window.notify(responce.data.status, 'Comment added successfully');
                }
                window.$('.comment_post_'+post_id).val('');
                window.$('.modal_comment_post_'+post_id).val('');
            }
        },
        async getPostsList() {
            let form = {
                search_text: this.search_text,
                gender: this.gender,
                breed: this.breed_id,
                state: this.state,
                post_id: this.post_id
            };
            let responce = await this.$store.dispatch("getPostsList",form);
            this.posts = responce.data.data;
        },
        async appendPostsList() {
            var _this = this;
            let form = {
                search_text: this.search_text,
                gender: this.gender,
                breed: this.breed_id,
                state: this.state,
                post_id: this.post_id
            };
            let responce = await this.$store.dispatch("getPostsList",form);
            if(responce.data.data.length > 0){
                for (var i = 0; i < responce.data.data.length; i++) {
                    _this.posts.push(responce.data.data[i]);
                }
                window.setTimeout(function(){
                    _this.count = 1;
                }, 5000);
            }
        },
        async getLikeList() {
            let form = {
                post_id: this.post_id
            };
            let responce = await this.$store.dispatch("getLikeList",form);
            this.likes = responce.data.data.reverse();
            window.$('.likes-modal').modal('show');
        },
        async getCommentList() {
            let form = {
                post_id: this.post_id
            };
            let responce = await this.$store.dispatch("getCommentList",form);
            this.comments = responce.data.data.reverse();
            window.$('.comments-modal').modal('show');
        },
        async getBreedList() {
            let responce = await this.$store.dispatch("getBreedList");
            if (responce.status) {
                this.breeds = responce.data.data;
            }
        },
        async getStateList() {
            let responce = await this.$store.dispatch("getAllStateList");
            if (responce.status) {
                this.states = responce.data.data;
            }
        },
        async getAuthUser() {
            let responce = await this.$store.dispatch("getAuthUser");
            if (responce.status) {
                this.user = responce.data.data;
            }
        },
        async getfirendsList() {
          let responce = await this.$store.dispatch("chatRecents");
          if (responce.status) {
              this.user_list = responce.data.data.slice(0, 10);
          }
      },
    },
    created() {// this will before after DOM loads
        var _this = this;
        this.getAuthUser();
        this.getPostsList();
        this.getBreedList();
        this.getStateList();
        this.getfirendsList();
        window.setTimeout(function(){
            window.$(".input-description").emojioneArea({
                pickerPosition: "right",
                tonesStyle: "bullet",
            });
            var lastScrollTop = 0;
            window.$(window).scroll(function() {
                var st = window.$(this).scrollTop();
                if (st > lastScrollTop){
                    if(_this.count == 1){
                        if(_this.posts.length >= 15){
                            let buffer = 400 // # of pixels from bottom of scroll to fire your function. Can be 0

                            if (window.$(".dm_feed_page").prop('scrollHeight') - window.$(window).scrollTop() <= window.$(window).height() + buffer){
                                _this.post_id = _this.posts[_this.posts.length - 1].id;
                                _this.count = 0
                                _this.appendPostsList();


                            }
                        }
                    }

                }
            });

        }, 500);
    }
};
</script>
